import { Button, Collapse, Divider, Pagination, Select, Table } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Layout } from "../../components/Layout";
import AddModeratorModal from "../../components/Modal/AddModeratorModal";
import DeleteModeratorModal from "../../components/Modal/DeleteModeratorModal";
import { SelectUi } from "../../components/ui";
import {
  createModeratorRequest,
  deleteModeratorRequest,
  getModeratorsAccessRequest,
  getModeratorsRequest,
} from "../../models/moderators/moderators";
import { getOfficeRequest } from "../../models/office/office";
import {
  getAllHistoryRequest,
  getHistoryLengthRequest,
  getUserHistoryLengthRequest,
  getUserHistoryRequest,
} from "../../models/user/user";
import {
  IconAngleDownSquare,
  IconAnglesRight,
  IconClipboardTimes,
  IconTrash,
  IconUsersGroup,
} from "../../utils/Icons";

const { Option } = Select;

const Moderators = () => {
  const [isModalAddModerator, setIsModalAddModerator] = useState(false);
  const [isModalDeleteModerator, setIsModalDeleteModerator] = useState(false);
  const [choosedModeratorsForDelete, setChoosedModeratorsForDelete] =
    useState(null);
  const [mappedDataModerators, setMappedDataModerators] = useState([]);
  const [mappedDataModeratorsFormated, setMappedDataModeratorsFormated] =
    useState([]);
  const [selectedModerator, setSelectedModerator] = useState(null);
  const [mappedDataHistory, setMappedDataHistory] = useState([]);
  const [historyPaginationData, setHistoryPaginationData] = useState({
    length: null,
    pageLimit: 10,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [formAddModerators, setFormAddModerators] = useState({
    name: "",
    moderator: "",
    plan: null,
  });

  const [officesList, setOfficesList] = useState(null);

  const showModalAddModerator = () => {
    setIsModalAddModerator(true);
  };

  const showModalDeleteModerator = (record) => {
    setChoosedModeratorsForDelete([record.key]);
    setIsModalDeleteModerator(true);
  };

  const handleCancelModalAddModerator = () => {
    setIsModalAddModerator(false);
  };

  const handleCancelModalDeleteModerator = () => {
    setChoosedModeratorsForDelete(null);
    setIsModalDeleteModerator(false);
  };

  const handleAddModerator = () => {
    const newEntry = {
      key: String(mappedDataModerators.length + 1),
      office: "-",
      ...formAddModerators,
    };

    if ((newEntry.name, newEntry.password)) {
      createModeratorRequest(newEntry.name, newEntry.password)
        .then(() => {
          // setMappedDataModerators((prev) => [...prev, newEntry]);
          fetchModerators();
          handleCancelModalAddModerator();
        })
        .catch((err) => {
          console.warn("Error createModeratorRequest", err);

          // Check if the error is an Axios error to get a more descriptive message
          if (err.response?.data === "invalid length of password or login") {
            // Show error toast
            toast.error(
              "Логин должен содержать более 1 символа, а пaроль более 4 символов!"
            );
          } else {
            const errorMessage = err.response
              ? err.response.data || "Create moderator error"
              : "Network error";

            // Show error toast
            toast.error(errorMessage);
          }
        });
    }
  };

  const columns = [
    {
      title: `Модератор (Логин)`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `Офис`,
      dataIndex: "office",
      key: "office",
    },
    {
      title: `Действия`,
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Button type="text" onClick={() => showModalDeleteModerator(record)}>
          <IconTrash width="20" height="20" fill="#d94c48" />
        </Button>
      ),
      width: "5%",
    },
  ];

  const historyDefinitions = {
    UpdateOfficeDailyDataFromModerator: "Обновил данные офиса за период",
    UpdateClosedClientFromModerator: "Обновил закрытых клиентов",
  };

  const handleSubmitDeleteModerator = () => {
    if (!choosedModeratorsForDelete) return;
    choosedModeratorsForDelete?.forEach((moderator) => {
      deleteModeratorRequest(moderator)
        .then(() => {
          setIsModalDeleteModerator(false);
          setMappedDataModerators((prev) =>
            prev.filter((item) => item.key !== moderator)
          );
        })
        .catch((err) => {
          console.warn("Delete moderators (deleteModeratorRequest) error", err);

          // Check if the error is an Axios error to get a more descriptive message
          const errorMessage = err.response
            ? err.response.data.message || "Delete moderators error"
            : "Network error";

          // Show error toast
          toast.error(errorMessage);
        });
    });
  };

  const fetchModerators = useCallback(() => {
    // get all moderators
    getModeratorsRequest()
      .then(({ data }) => {
        if (!data) return;
        setMappedDataModerators(
          data.map((el) => ({
            key: el.ID,
            name: el.Login,
            office: null,
          }))
        );
      })
      .catch((err) => {
        console.warn("Error getModeratorsRequest", err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || "Get moderators error"
          : "Network error";

        // Show error toast
        toast.error(errorMessage);
      });
  }, []);

  useEffect(() => {
    // get all offices
    getOfficeRequest()
      .then(({ data }) => setOfficesList(data))
      .catch((err) => {
        console.warn("Error getOfficeRequest", err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || "Get office error"
          : "Network error";

        // Show error toast
        toast.error(errorMessage);
      });

    fetchModerators();

    // get history length
    const historyLengthQuery = selectedModerator
      ? getUserHistoryLengthRequest(selectedModerator)
      : getHistoryLengthRequest();
    historyLengthQuery.then(({ data }) => {
      setHistoryPaginationData((prev) => ({
        ...prev,
        length: data,
      }));
    });
  }, [fetchModerators, selectedModerator]);

  useEffect(() => {
    // get all history by each moderator
    if (!officesList) return;
    setMappedDataHistory([]);
    const query = selectedModerator
      ? getUserHistoryRequest(selectedModerator, currentPage)
      : getAllHistoryRequest(currentPage);
    query
      .then(({ data }) => {
        if (!data) return;
        let mappedDataHistoryFormated = [];
        data.map((action) => {
          return (mappedDataHistoryFormated = [
            ...mappedDataHistoryFormated,
            {
              id: action.ID,
              name: action.UserLogin,
              office:
                (officesList &&
                  officesList.find(
                    (office) => office.ID === action.Description.OfficeId
                  )?.Name) ||
                action.Description.OfficeId,
              action: action.Action,
              change: historyDefinitions[action.Action] || action.Action,
              date: action.CreatedAt
                ? DateTime.fromISO(action.CreatedAt).toFormat("yyyy-MM-dd")
                : "-",
              officeId: action.Description.OfficeId,
              description: {
                ...action.Description,
                Day: action.Description?.Day
                  ? DateTime.fromISO(action.Description.Day).toFormat(
                      "yyyy-MM-dd"
                    )
                  : "-",
              },
            },
          ]);
        });
        setMappedDataHistory(mappedDataHistoryFormated);
      })
      .catch((err) => {
        console.warn("Error getAllHistoryRequest", err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || "Get user history error"
          : "Network error";

        // Show error toast
        toast.error(errorMessage);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officesList, currentPage, selectedModerator]);

  useEffect(() => {
    if (!mappedDataModerators || !officesList) return;

    const fetchModeratorAccess = async () => {
      // Create an array of promises for all requests
      const updatedDataModerators = await Promise.all(
        mappedDataModerators.map(async (moderator) => {
          if (moderator.office) return moderator;

          try {
            const { data } = await getModeratorsAccessRequest(
              Number(moderator.key)
            );

            const officeNames = data
              ? data
                  .map(
                    (obj) =>
                      officesList.find((office) => office.ID === obj.OfficeId)
                        ?.Name
                  )
                  .join(", ")
              : "-";

            return { ...moderator, office: officeNames };
          } catch (err) {
            console.warn("Error getModeratorsAccessRequest", err);

            // Check if the error is an Axios error to get a more descriptive message
            const errorMessage = err.response
              ? err.response.data.message || "Get moderators access error"
              : "Network error";

            // Show error toast
            toast.error(errorMessage);

            // Return object without changes if we got an error
            return moderator;
          }
        })
      );

      // После завершения всех запросов обновляем состояние
      setMappedDataModeratorsFormated(updatedDataModerators);
    };

    fetchModeratorAccess();
  }, [mappedDataModerators, officesList]);

  const handleModeratorChange = (value) => {
    setSelectedModerator(value);
  };

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`moderator`}>
          <h4 className="title">
            <span>
              <IconUsersGroup width="25" height="25" fill="#00c39a" />
              Модераторы
            </span>

            <Button
              htmlType="button"
              onClick={showModalAddModerator}
              className="xs"
            >
              Добавить Модератора
            </Button>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataModeratorsFormated}
            columns={columns}
          />

          <Divider />

          <h5>
            История изменений
            <SelectUi
              name={"moderator"}
              label={`Выберите модератора`}
              onChange={handleModeratorChange}
              placeholder={`Выберите модератора`}
            >
              {mappedDataModeratorsFormated &&
                mappedDataModeratorsFormated.map(({ key, name }, i) => (
                  <Option value={key} key={`moderator-${i}`}>
                    {name}
                  </Option>
                ))}
            </SelectUi>
          </h5>
          <div className="historyWrapper">
            <div className="history">
              {mappedDataHistory.length > 0 ? (
                <Collapse
                  className={`item ${!selectedModerator && "inactive"}`}
                  expandIconPosition="end"
                  expandIcon={({ isActive }) =>
                    selectedModerator && (
                      <IconAngleDownSquare
                        width="24"
                        height="24"
                        fill="#00c39a"
                        rotate={isActive ? 180 : 0}
                      />
                    )
                  }
                  items={mappedDataHistory.map((item, index) => ({
                    key: index,
                    label: (
                      <div className="item__content">
                        <div className="name">
                          <span className="name-subtitle">Дата</span>
                          <span className="name-title">{item.date}</span>
                        </div>
                        <div className="name">
                          <span className="name-subtitle">День изменения</span>
                          <span className="name-title">
                            {item.description?.Day}
                          </span>
                        </div>
                        <div className="name">
                          <span className="name-subtitle">
                            Модератор (Офис)
                          </span>
                          <span className="name-title">
                            {item.name} ({item.office ? item.office : "-"})
                          </span>
                        </div>
                        <div className="name">
                          <span className="name-subtitle">Изменение</span>
                          <span className="name-title">{item.change}</span>
                        </div>
                      </div>
                    ),
                    children: (
                      <div className="item__desc">
                        {item.action ===
                          "UpdateOfficeDailyDataFromModerator" && (
                          <>
                            <div className="item__desc-name">
                              Кол-во людей
                              <span>
                                {item.description.OfficePeopleCount &&
                                item.description.OfficePeopleCount !==
                                  item.description.PrevOfficePeopleCount &&
                                item.description.PrevOfficePeopleCount ? (
                                  <>
                                    {item.description.PrevOfficePeopleCount}
                                    <IconAnglesRight
                                      width="14"
                                      height="14"
                                      fill="#fff"
                                    />
                                    <s>{item.description.OfficePeopleCount}</s>
                                  </>
                                ) : (
                                  item.description.OfficePeopleCount || "-"
                                )}
                              </span>
                            </div>
                            <div className="item__desc-name">
                              Результат в USDT
                              <span>
                                {item.description.EarnedUsdt &&
                                item.description.EarnedUsdt !==
                                  item.description.PrevEarnedUsdt &&
                                item.description.PrevEarnedUsdt ? (
                                  <>
                                    {item.description.PrevEarnedUsdt}
                                    $
                                    <IconAnglesRight
                                      width="14"
                                      height="14"
                                      fill="#fff"
                                    />
                                    <s>{item.description.EarnedUsdt}$</s>
                                  </>
                                ) : item.description.EarnedUsdt ? (
                                  `${item.description.EarnedUsdt}$`
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>
                            <div className="item__desc-name">
                              Результат в RUB{" "}
                              <span>
                                {item.description.EarnedRubles &&
                                item.description.EarnedRubles !==
                                  item.description.PrevEarnedRubles &&
                                item.description.PrevEarnedRubles ? (
                                  <>
                                    {item.description.PrevEarnedRubles}
                                    <IconAnglesRight
                                      width="14"
                                      height="14"
                                      fill="#fff"
                                    />
                                    <s>{item.description.EarnedRubles}₽</s>
                                  </>
                                ) : item.description.EarnedRubles ? (
                                  `${item.description.EarnedRubles}₽`
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>
                            <div className="item__desc-name">
                              Вывод в USDT
                              <span>
                                {item.description.WithdrawnUsdt &&
                                item.description.WithdrawnUsdt !==
                                  item.description.PrevWithdrawnUsdt &&
                                item.description.PrevWithdrawnUsdt ? (
                                  <>
                                    {item.description.PrevWithdrawnUsdt}
                                    <IconAnglesRight
                                      width="14"
                                      height="14"
                                      fill="#fff"
                                    />
                                    <s>{item.description.WithdrawnUsdt}$</s>
                                  </>
                                ) : item.description.WithdrawnUsdt ? (
                                  `${item.description.WithdrawnUsdt}$`
                                ) : (
                                  "-"
                                )}
                              </span>
                            </div>
                          </>
                        )}
                        {item.action === "UpdateClosedClientFromModerator" && (
                          <>
                            <div className="item__desc-name">
                              Город
                              <span>{item.description.City}</span>
                            </div>
                            <div className="item__desc-name">
                              Имя
                              <span>{item.description.Name}</span>
                            </div>
                            <div className="item__desc-name">
                              Телефон
                              <span>{item.description.Number}</span>
                            </div>
                          </>
                        )}
                      </div>
                    ),
                  }))}
                />
              ) : (
                <div className="empty-message">
                  <div className="message">
                    <IconClipboardTimes width="45" height="45" fill="#00c39a" />
                    История изменений пуста
                  </div>
                </div>
              )}
            </div>
            <Pagination
              current={currentPage}
              total={historyPaginationData.length}
              pageSize={historyPaginationData.pageLimit}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
              style={{ marginTop: "16px", textAlign: "center" }}
            />
          </div>
        </div>

        {/* Modal Moderator Office */}
        <DeleteModeratorModal
          isModalOpen={isModalDeleteModerator}
          moderatorsList={mappedDataModerators}
          choosedModeratorsForDelete={choosedModeratorsForDelete}
          handleCancel={handleCancelModalDeleteModerator}
          handleSubmit={handleSubmitDeleteModerator}
        />

        {/* Modal Add Moderator */}
        <AddModeratorModal
          isModalOpen={isModalAddModerator}
          handleAddData={handleAddModerator}
          handleCancel={handleCancelModalAddModerator}
          setFormAddOffice={setFormAddModerators}
        />
      </div>
    </Layout>
  );
};

export default Moderators;
