import { Divider, Table } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Layout } from "../../components/Layout";
import {
  getOfficeRequest,
  getOfficesDailyDataRequest,
} from "../../models/office/office";
import { IconUserCheck } from "../../utils/Icons";

const StaffOnline = () => {
  const currentDate = DateTime.now().toFormat("yyyy-MM-dd");

  const [officesList, setOfficesList] = useState(null);
  const [officesListDailyData, setOfficesListDailyData] = useState(null);

  useEffect(() => {
    getOfficeRequest()
      .then(({ data }) => {
        setOfficesList(data);
      })
      .catch((err) => {
        console.warn("Error getOfficeRequest", err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || "Get offices error"
          : "Network error";

        // Show error toast
        toast.error(errorMessage);
      });
  }, []);

  useEffect(() => {
    if (!officesList) return;
    const fetchOfficesDailyData = async () => {
      // Create an array of promises for all requests
      const updatedDailyDataOffices = await Promise.all(
        officesList.map(async (office) => {
          try {
            const { data } = await getOfficesDailyDataRequest(
              office.ID,
              currentDate
            );

            return {
              ...office,
              total: data?.DailyData.OfficePeopleCount || 0,
              online: data?.DailyData.LinePeopleCount || 0,
              earnedUsdt: data?.DailyData.EarnedUsdt || 0,
            };
          } catch (err) {
            return office;
          }
        })
      );

      if (updatedDailyDataOffices)
        setOfficesListDailyData(updatedDailyDataOffices);
    };

    // initial fetch
    fetchOfficesDailyData();

    // Fetch every 2sec for make it online
    const intervalId = setInterval(() => {
      fetchOfficesDailyData();
    }, 2_000);

    // Cleanup the interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [currentDate, officesList]);

  console.log(officesListDailyData);

  const officesListDailyDataFormated = officesListDailyData?.filter(
    (office) => !!office?.total
  );

  // const totalSum = officesListDailyDataFormated?.reduce(
  //   (acc, current) => acc + (Number(current.total) || 0),
  //   0,
  // );

  const onlineSum = officesListDailyDataFormated?.reduce(
    (acc, current) => acc + (Number(current.online) || 0),
    0
  );

  const officesListDailyDataEarnedUSDT = officesListDailyData?.reduce(
    (acc, current) => acc + (Number(current?.earnedUsdt) || 0),
    0
  );

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: `Результат`,
      dataIndex: "earnedUsdt",
      key: "earnedUsdt",
      render: (_, record) => `${record.earnedUsdt}$`,
    },
    {
      title: `Кол-во людей на работе`,
      dataIndex: "online",
      key: "online",
    },
  ];

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`staff`}>
          <h4 className="title">
            <span>
              <IconUserCheck width="25" height="25" fill="#00c39a" />
              Сегодня
            </span>

            <div className="total">
              Результат <b>{officesListDailyDataEarnedUSDT}$</b>
            </div>
            <div className="total">
              Общее количество людей на работе <b>{onlineSum}</b>
            </div>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={officesListDailyDataFormated}
            columns={columns}
            scroll={{ x: 768 }}
            pagination={false}
          />
        </div>
      </div>
    </Layout>
  );
};

export default StaffOnline;
